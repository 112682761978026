// check, that property exists in object
export const Exists = (obj = {}, prop = '') => {
    if (prop.indexOf('.') + 1 === 0) {
        return obj.hasOwnProperty(prop);
    }

    let props = prop.split('.');
    if (obj.hasOwnProperty(props[0])) {
        return Exists(obj[props[0]], props.splice(1, props.length).join('.'))
    }

    return false;
};

export default Exists;
