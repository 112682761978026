export const EN = {
    LOADING: 'Loading',
    DASHBOARD: 'Dashboard',
    CATEGORIES: 'Categories',
    TAGS: 'Tags',
    TRENDS: 'Trends',
    BANNERS: 'Banners',
    WIDGETS: 'Widgets',
    PAGES: 'Pages',
    USERS: 'Users',
    DOCUMENTS: 'Documents',
    ARTICLE: 'Article',
    EPISODE: 'Episode',
    GALLERY: 'Gallery',
    INFOGRAPHIC: 'Infographic',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
    NO_EVENTS: 'no events for you',
    WELCOME_MESSAGE: 'Welcome to admin panel',
    DASHBOARD_WELCOME: 'Welcome to admin panel',
    ARTICLE_LIST: 'Articles list',
    EPISODE_LIST: 'Episodes list',
    GALLERY_LIST: 'Gallery list',
    INFOGRAPHIC_LIST: 'Infographics list',
    LOADING_DOCUMENTS: 'Documents loading',
    EDIT: 'edit document',
    VIEW: 'preview document',
    DELETE: 'delete document',
    CROSSLINKS: 'crosslinks',
    SHOW_ALL_TAGS: 'show all tags',
    HIDE_ALL_TAGS: 'hide all tags',
    IMAGE: 'image',
    COVER: 'cover',
    VIDEO: 'video',
    EXTERNALVIDEO: 'external video',
    CODE: 'code',
    CATEGORY: "Category",
    AUTHOR: 'Author',
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    PREPUBLISHED: 'prepublished',
    CREATE: 'Create',
    TITLE_OR_URL: 'title or url',
    SELECT_STATUS: 'select status',
    SELECT_TAGS: 'select tags',
    SELECT_CATEGORY: 'select category',
    DATE_FROM: 'date from',
    DATE_TO: 'date till',
    ALL: 'all',
    EMPTY_DOCUMENTS: 'No documents',
    CLEAR_FILTER: 'clear_filter',
    CREATED: 'Created',
    UPDATED: 'Updated',
    AT: 'at',
    SURE_DELETE: 'You are sure that you want to delete this document?',
    CLOSE: 'Close',
    NOT_SET: '[not set]',
    ARTICLE_ADD: 'Add article',
    UPLOAD_TEXT: 'Click or drag file to this area to upload',
    COVER_LABEL: 'Cover',
    VIDEO_LABEL: 'Video',
    UPLOAD_NEW: 'New',
    UPLOAD_SEL: 'Select',

    NEWS: 'News',
    ARTICLES: 'Articles',
    TITLE: 'Title',
    UPDATE_DOCUMENT: 'Save document',
    DOC_STATUS: 'Status',
    CREATE_BY: 'Created by',
    UPDATED_BY: 'Updated by',
    HISTORY: 'History',
    SUMMARY: 'Summary',
    DELETE_MEDIA: 'Delete media',
    TEXT: 'Document text',
    SLUG: 'Slug (link)',
    PUBLISHED_AT: 'Published At',
    COORDS: 'Coordinates',
    COORDS_HELP: 'Enter place coordinates',
    HIDE_MAP: 'Hide map',
    SHOW_MAP: 'Show map',
    GENERATE: 'Generate',
    PUBLISH_IN: 'Publish in',
    SUB_CATEGORY: 'Sub category',
    VIEW_FIELD: 'View field',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Redirect to',
    SEO_AND_VIEW: 'View settings',
    published: 'Published',
    DOCUMENT_HISTORY_EVENT: 'Document history',
    RECOVER: 'Recover',
    TITLE_ERROR_FILL: 'Fill document title',
    SUMMARY_ERROR_FILL: 'Fill document summary',
    SLUG_PLACEHOLDER: 'Fill document slug',
    SLUG_ERROR: 'Fill document slug',
    SELECT_CATEGORIES: 'Select document category',
    SELECT_AUTHORS_ERROR: 'Select document author',
    AUTHORS_PLACEHOLDER: 'Select document author',
    TITLE_PLACEHOLDER: 'Enter title',
    SUMMARY_PLACEHOLDER: 'Enter summary',
    SELECT_SUB_CATEGORIES: 'Select subcategory',
    SELECT_TRENDS: 'Select trends',
    SELECT_CROSSLINKS: 'Select crosslinks',
    NO_HISTORY: 'No history',
    ARTICLE_EDIT: 'Edit article',
    EPISODE_ADD: 'Add episode',
    EPISODE_EDIT: 'Edit episode',
    COORDS_MAP_HELP: 'Click on map to select point',
    GALLERY_EDIT: 'Edit gallery',
    CREATE_BTN: 'Create',
    PUBLIC_ID: 'ID',
    CREATED_AT: 'Created at',
    UPDATED_AT: 'Updated at',
    ACTIONS: 'ACtions',
    CREATE_DOCUMENT: 'Create',
    DOCUMENT_UPDATE_ERROR: 'Failed update document',
    DOCUMENT_UPDATE_SUCCESS: 'Document successfully updated',
    DOCUMENT_DELETED: 'Document successfully deleted',
    DOCUMENT_DELETE_ERROR: 'Failed delete document',
    DOCUMENTS_LOAD_ERROR: 'Documents load failed',
    REPEAT_AGAIN_LATER: 'Try again later',
    DOCUMENT_UPDATED: 'Document successfully updated',
    LOGIN: 'Login',
    AUTHORIZATION_WAIT: 'Authorization',
    AUTHORIZATION_ERROR: 'Authorizatiion failed',
    CLEAR: 'Clear',
    APPLY: 'Apply',
    SEARCH_MEDIA_PLACEHOLDER: 'Title or copyright',
    REMOVE: 'Remove',
    ADD: 'Add',
    SOURCE: 'Source',
    DELETE_VIDEO: 'Delete video',

    REMOVE_ALL: 'Remove all',
    SAVE_ALL: 'Save all',
    UPLOAD_FIRST_ROW: 'Click or drag file on upload area',
    UPLOAD_NEXT_ROW: ' ',
    MEDIA: 'Media',
    UPLOAD: 'Upload',
    SEARCH: 'Search',
    MEDIA_LIST: 'Media list',
    WIDGET_ADD_TITLE: 'Add widget',
    EDIT_WIDGET: 'Edit widget',
    VIEW_WIDGET: 'View widget',
    WIDGET_UPDATED: 'Widget updated',
    WIDGET_CREATED: 'Widget created',
    MAX_SIZE: 'Max documents count',
    DOCS_SIZE: 'Documents count',
    DOC_TYPES: 'Documents types',
    ADMIN_TITLE: 'Title (admin)',
    WIDGETS_LIST: 'Widgets list',
    SAVE: 'Save',
    WIDGET_NOT_FOUND: 'Widget not found',
    MAXIMUM_ELEMENT_IN_WIDGET: 'Widget documents count',
    WIDGETS_EDIT: 'Edit widget',
    TAG_LIST: 'Tags list',
    CATEGORY_LIST: 'Category list',
    AUTHORS_LIST: 'Authors list',
    PAGES_LIST: 'Pages list',
    HREF: 'Link',
    TEXT_PLACEHOLDER: 'Document text',
    REDIRECT_TO_ERROR: 'Redirect fill error',
    SLUG_FILL_ERROR: 'Document fill error',
    PAGE_LIST: 'Pages list',
    BANNER_LIST: 'Banners list',
    TREND_LIST: 'Trends list',
    TREND_ADD: 'Add trend',
    TREND_EDIT: 'Edit trend',
    BANNER_ADD: 'Add banner',
    BANNER_EDIT: 'Edit banner',
    OTHER: 'Other',
    PREVIEW: 'Preview',
    WIDGET_TITLE: 'Widget title',
    ADMIN_WIDGET_TITLE: 'Widget title (admin)',
    AUTHOR_LIST: 'Authors list',
    DOCUMENT_CREATE_FINISH: 'Document successfully created',
    AUTHOR_EDIT: 'Edit author',
    AUTHOR_ADD: 'Add author',
    LOADING_DOCUMENT: 'Load document',
    SELECT: 'Select',
    VISIBLE: 'Visible on site',
    SHOW: 'Shows',
    SHOW_LIST: 'Shows list',
    SHOW_ADD: 'Add show',
   
    ERROR_SET_LOCK: 'Set lock failed',
    SUCCESS_SET_LOCK: 'Lock successfully set',
    IN_MATERIAL: 'In material',
    DOCUMENT_LOCK: 'Document locked',
    REMOVE_LOCK: 'Remove lock',
    LOCK_REMOVE: 'Lock removed',
    LOCK_REMOVE_ERROR: 'Failed remove lock',
    ERROR_CREATE_DOCUMENT: 'Failed create document',
    DOCUMENT_CREATED: 'Document successfully created',
    DOCUMENT_NOT_FOUND: 'Documetn not found',
    STASH_CHANGES: 'You have not saved changes',
    DOCUMENT_LAST_EDIT_USER: 'Last document updates',
    DOCUMENT_LAST_EDIT_STASH: 'Last autosave',
    DELETE_STASH: 'Delete authosave',
    USER_BY: 'by user',
    STATUS: 'status',
    PUBLISH_CHECK: "Published, check",
    PUBLISH: "Published",
    ERROR_LOAD_WORKFLOW: 'Failed load documents',
    STATUS_NOT_SET: 'not set',
    IMAGE_FIELD: 'Image',
    VIDEO_FIELD: 'Video',
    RAITER_FIELD: 'Reporter',
    EDITOR_FIELD: 'Editor',
    CHECK_FIELD: 'Corrector',
    VIEWS_AND_COMMENTS: 'Nubber of views/comments',
    LAST_UPDATED_WORKFLOW: 'Last changes',
    DATE_PUBLISH: 'Publish date',
    WORKFLOW_STATS: 'Status',
    COVER_WORKFLOW: 'Photo',
    VIEWS_COUNT: 'Number of views',
    COMMENTS_COUNT: 'Number of comments',
    LOCK_BY: 'Locked',
    READY: 'Ready',
    ARCHIVE: 'Archive',
    PUBLISH_AT_MAIN: 'Publish on top',
    TYPE_ARTICLE: 'Article',
    TYPE_NEWS: 'News',
    TYPE_EPISODE: 'Episode',
    TYPE_INFOGRAPHIC: 'Infografics',
    TYPE_TREND: 'Trends',
    TYPE_BANNER: 'Banners',
    TYPE_MEANING: "Meanings",
    ARCHIVE_LIST: 'Archive',
    TYPE: 'Type',
    CREATED_BY: 'User',
    ERROR_LOAD_ARCHIVE: 'Failed load archive',
    SEARCH_PLACEHOLDER: 'Title or slug',
    EDITOR_INSERT_MEDIA: 'Insert',
    INSERT_PLUGIN: 'Insert',
    INSERT_IMAGE: 'Image',
    INSERT_VIDEO: 'Video',
    ERROR_LOAD_MEDIA: 'Failed load media',
    INSERT: 'Insert',
    MEDIA_TITLE: 'Title',
    FILL_MEDIA_TITLE: 'Enter description',
    MEDIA_SOURCE: 'Source',
    MEDIA_COPYRIGHT: 'Copyright',
    MEDIA_COPYRIGHT_URL: 'Link to source',
    MEDIA_DELETE: 'Delete',
    MEDIA_UPLOAD: 'Upload',
    EDIT_MEDIA: 'Edit media',
    SELECT_MEDIA: 'Select from archive',
    MEDIA_SOURCES: 'Sources',
    ERROR_UPDATE_MEDIA: 'Failed update',
    INSERT_EXTERNALVIDEO: 'External video',
    INCORRECT_SOURCE: 'Unknown source',
    INSERT_CODE: 'Code',
    WIDTH: 'Width',
    HEIGHT: 'Height',
    EMBEDDED: 'Embed',
    EMBEDDED_EMPTY: 'Embed can not be empty',
    ERROR_CREATE_CODE: 'Failed create code',
    VIDEOS: 'Videos',
    EVENTS: 'Events',
    SERVICES: 'Services',
    PLACES: 'Places',
    FILMS: 'Films',
    BOOKS: 'Books',
    MUSIC: 'Music',
    ROUTES: 'Routes',
    FRENCH_QUOTES: '«»',
    MIDDLE_DASH: '–',
    LONG_DASH: '—',


    AUTHORIZATION_EXPIRED: 'Authorization expired',
    YOUR_AUTHORIZATION_EXPIRED: 'Authorization expired. Do you want to continue?',
    RESUME: 'Resume',
    CURRENT_VERSION: 'Current version',
    PREVIEW_VERSION: 'Previous version',
    YOU_ARE_USER_RECOVERY: 'Resume?',


    ERROR_LOAD_PROFILES: 'Failed load profiles',
    PROFILE_LOGIN: 'Login',
    PROFILE_CREATED: 'Registration date',
    PROFILE_AVATAR: 'Avatar',
    PROFILE_EMAIL: 'ID',
    PROFILE_NAME: 'Name',
    PROFILE_ACTIVE: 'Active',
    PROFILE_APPROVED: 'Approved',
    PROFILE_TYPE: 'Type',
    EDIT_PROFILE: 'Edit profile',
    PROFILE_SEARCH_PLACEHOLDER: 'Login / Email / Name / Surname',

    ERROR_LOAD_GROUPS: 'Failed load groups',
    GROUPS_SEARCH_PLACEHOLDER: 'Name / code of group',
    GROUPS_LIST: 'User goups list',
    CREATE_GROUP: 'Add group',

    PERMISSIONS_LIST: 'Permissions list',
    CREATE_PERMISSION: 'Create',
    PERMISSIONS_PLACEHOLDER: 'Code / Title / Description of permission',
    ERROR_LOAD_PERMISSIONS: 'Failed load permissions',
    PERMISSION_CREATED: 'Permission successfully created',
    ERROR_CREATE_PERMISSION: 'Failed create permission. Permission already exist',
    PERMISSION_UPDATED: 'Permission successfully updated',
    ERROR_UPDATE_PERMISSION: 'Failed update permission',
    PERMISSION_CODE: 'Permission code',
    PERMISSION_CODE_FILL: 'Enter permission code',
    PERMISSION_TITLE: 'Title',
    PERMISSION_TITLE_FILL: 'Enter permission title',
    PERMISSION_DESCRIPTION: 'Description',
    DELETE_PERMISSION: 'Delete permission',
    EDIT_PERMISSION: 'Edit',
    DELETE_PERMISSION_REQUEST: 'Do you want to delete the permission?',
    ERROR_DELETE_PERMISSION: 'Failed delete permission',
    SUCCESS_DELETE_PERMISSION: 'Permisson successfully deleted',
    UPDATE: 'Update',
    DESCRIPTION: 'Description',
    GROUP_TITLE: 'Group title',
    GROUP_PERMISSIONS: 'Group permissions',
    GROUP_UPDATE_FINISH: 'Group successfully updated',
    GROUP_UPDATE_ERROR: 'Failed update group',
    GROUP_CREATE_FINISH: 'Group successfully created',
    GROUP_CREATE_ERROR: 'Failed create group',
    GROUP_DELETE_FINISH: 'Group successfully deleted',
    GROUP_DELETE_ERROR: 'Failed delete group',
    DELETE_GROUP_REQUEST: 'Do you want to delete the group?',
    DELETE_GROUP: 'Delete group',
    EDIT_GROUP: 'Edit group',
    ACTIVE: 'Active',
    PERMISSION: 'Permissions',
    GROUP_TITLE_FILL: 'Enter group title',
    UPDATE_GROUP: 'Update group',
    PROFILE_EDIT: 'Edit profile',
    ERROR_UPDATE_PROFILES: 'Failed update profile. Try again later',
    PROFILES_UPDATED: `Profile successfully updated`,
    ERROR_LOAD_PROFILE: 'Profile not found',
    PROFILE_BIRTHDAY: 'Birth date',
    PROFILE_ABOUT: 'Info',
    PROFILE_ADDRESS: 'Address',
    PROFILE_LAST_NAME: 'Surname',
    PROFILE_PASSWORD: `Password`,
    PROFILE_GROUPS: 'Groups',
    PROFILE_INFO: 'Profile info',
    PROFILE_SUBSCRIBES: 'Profile subscriptions',
    PROFILES: 'Profiles',


    UNPUBLISH_ACTION: 'Unpublish',
    PUBLISH_ACTION: 'Publish',
    PUBLISH_POPCONFIRM: 'Do you really want to publish this document?',

    PUSH: 'Push notifications',
    SEND_PUSH: 'Send push',
    PUSH_SEND: 'Push successfully send',
    PUSH_SEND_ERROR: 'Faild send push',

    SELECT_MATERIAL: 'Select material',
    NOTIFICATIONS: 'Notifications',
    NOTIFICATION_IN: 'Material views count',
    DOWNLOAD: 'Download',

    /*media gallery*/
    MEDIA_GALLERY_LIST: `Фотоархив`,
    MEDIA_GALLERY_LIST_LOAD_ERROR: `Ошибка загрузки данных`,
    MEDIAGALLERY_LOADING_ERROR_MESSAGE: `Во время загрузки произошла ошибка`,
    MEDIAGALLERY_LOADING_ERROR_DESCRIPTION: `Повторите попытку позже или свяжитесь с разработчиками`,
    MEDIAGALLERY_RELOAD: `Повторить попытку`,
    MEDIAGALLERY_VIEW_CARD: 'Сетка',
    MEDIAGALLERY_VIEW_LIST: 'Список',
    MEDIAGALLERY_OBJECT: `Объектов`,
    MEDIAGALLERY_TYPE_IMAGE: 'Изображение',
    MEDIAGALLERY_TYPE_COVER: 'Обложка',
    MEDIAGALLERY_TYPE_VIDEO: 'Видео',
    MEDIAGALLERY_EDIT_IMAGE: `Редактировать изображение`,
    MEDIAGALLERY_DOWNLOAD_IMAGE: `Скачать оригинал`,
    MEDIAGALLERY_DELETE_IMAGE: `Удалить изображение`,
    MEDIAGALLERY_DELETE_CONFIRM: `Вы уверены, что хотите удалить изображение?`,
    MEDIAGALLERY_DELETE_OK: 'Да, уверен!',
    MEDIAGALLERY_DELETE_REJECT: `Нет`,
    MEDIAGALLERY_TYPE_PDF: 'PDF',
    MEDIAGALLERY_SELECT_TYPE: 'Выберите тип',
    MEDIAGALLERY_FILL_SEARCH: 'Введите поисковый запрос',
    MEDIGALLERY_UPLOAD_TITLE: `Выбрать файлы`,
    MEDIAGALLERY_SINGLE_EDIT: 'Одиночное',
    MEDIAGALLERY_GROUP_EDIT: 'Групповое',
    MEDIGALLERY_CANNOT_GENERATE_PREVIEW: 'Невозможно показать превью',
    MEDIAGALLERY_UPLOAD_TITLE: 'Загрузка файлов',

    ADMIN_WIDGET_CODE: 'Кодовое название виджета',
    ADMIN_WIDGET_CODE_ERROR: 'Заполните кодовое название виджета',
    ADMIN_WIDGET_CODE_PLACEHOLDER: 'Не указан кодовое название виджета',

    BOLD_TEXT: 'Выделение текста',
    BOLD_TEXT_SELECTED: 'bold',

    NEXT_EPISODE: 'Next episode date',
    ORIGINAL_YT_LINK: 'Original youtube link'

};

export default EN;
