import MediaSources from "../../media_sources";

const PROJECT = process.env.PROJECT || "";

export const alv_prod = {
  PROJECT_NAME: "ALV",
  PROJECT_PREFIX: "alv",
  BASE_DOMAIN: "https://ahilesva.info",
  USE_MONGO: true,

  // settings for menu
  MEDIA: {
    // path for cdn content
    CDN: {
      IMAGES: "https://mf.ahilesva.info/image",
      VIDEO: "https://mf.ahilesva.info/video",
    },
    // available copyright sources
    SOURCES: MediaSources,
    // available upload types
    UPLOAD_AVAILABLE: ["jpg", "gif", "png", "jpeg", "mp4", "mp3"],
    // available embedded sources
    EMBEDDED_SOURCES: [
      "youtube.com",
      "facebook.com",
      "youtu.be",
      "afp.com",
      "ruptly.tv",
    ],
  },
  WIDGETS: {
    TYPES: ["Category", "Gallery"],
  },
  PROJECT: {
    // project name
    NAME: PROJECT,
    // project default layout
    LAYOUT: "default",
    // project api path
    API: `https://backend.ahilesva.ru/api/backend`,
    // WEBSOCKET: 'wss://backend.ahilesva.info/api/backend/youtube/tasks'
    WEBSOCKET: "wss://backend.ahilesva.ru/ws",
  },

  UPLOAD: {
    path: "https://backend.ahilesva.ru/api/backend/media",
  },
  // oauth configuration
  OAUTH: {
    // url for oauth domain
    URL: `https://auth.rt.com/`,
    // current project domain
    PROJECT_DOMAIN: `backend.ahilesva.ru`,
    // current project url path
    PROJECT_PATH: `https://backend.ahilesva.ru`,
    // path for oauth api
    API: `https://auth.rt.com/auth`,
    // path for oauth users
    OAUTH_USER_PATH: `https://auth.rt.com/users/`,
    // prefix for authorization tokens
    PREFIX: "oauth_",
    AUTH_HEADER: "X-Access-Token",
  },

  VIEWS: {
    documents: {
      gallery: "default_document",
      category: "category_view",
      tag: "tag_view",
      page: "page_view",
    },
    documents_add: {
      category: "add_category",
      tag: "add_tag",
      page: "add_page",
    },
  },
  MENU: [
    {
      title: "DASHBOARD",
      path: "/",
      component: "dashboard_index",
      icon: "dashboard",
    },
    {
      title: "DOCUMENTS",
      path: "/documents/gallery",
      icon: "container",
      subroutes: [
        {
          path: "/documents/:type",
          component: "default_document",
        },
        {
          path: "/documents/:type/:id",
          component: "add_documents",
        },
        {
          path: "/documents/:type/:id/preview",
          component: "preview",
        },
        {
          path: "/documents/:type/create",
          component: "add_documents",
        },
      ],
    },
    {
      title: "PUSH",
      path: "/push",
      icon: "notification",
      component: "push",
    },
    {
      title: "CATEGORIES",
      path: "/documents/category",
      icon: "folder",
      component: "default_document",
    },

    {
      title: "MEDIA",
      path: "/media",
      icon: "picture",
      component: "media",
    },
    {
      title: "WIDGETS",
      path: "/widgets",
      icon: "appstore",
      component: "widget",
      subroutes: [
        {
          path: "/widgets/:id",
          component: "widget_edit",
        },
      ],
    },
  ],
};

export default alv_prod;
