import MediaSources from "../../media_sources";
import {parseUrlParams} from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";

export const staging = {
    LOCALE: "en",
    SHOW_LANGS: true,
    APP_CLASS: 'staging',
    PROJECT_NAME: 'staging',
    PROJECT_PREFIX: 'staging',
    USE_MONGO: true,
    BASE_DOMAIN : 'https://hasta-la-vista.dev.rt.com',
    LANGS: [
        {title: "English", domain: "http://admin-hasta-la-vista.dev.rt.com"},
        // {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    // settings for menu
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://hasta-la-vista.dev.rt.com/image",
            VIDEO: "https://hasta-la-vista.dev.rt.com/video",
            // PDF: "https://<<HOST>>/pdf/",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be',  'afp.com', 'ruptly.tv'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=<<APPID>>&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://admin-hasta-la-vista.dev.rt.com${path.replace('https://admin-hasta-la-vista.dev.rt.com', '')}`;
            return path;
        },
    },
    WORKFLOW: {
        SHOW_SEARCH: true,
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            switch(item.type.toLowerCase()) {
                case "gallery":return `https://admin-hasta-la-vista.dev.rt.com/photo/${item.publicId}-${item.slug}`;
                case "category":return `https://admin-hasta-la-vista.dev.rt.com/${item.slug}`;
                case "tag":return `https://admin-hasta-la-vista.dev.rt.com/tag/${item.slug}`;
                case "page":return `https://admin-hasta-la-vista.dev.rt.com/static/${item.slug}`;
                default: return `https://admin-hasta-la-vista.dev.rt.com/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            }
        },
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT' : 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: ['gallery'],
        WORKFLOW_FOR: [
            'gallery',
        ],
    },
    WIDGETS: {
        TYPES: ['Category', "Gallery"]
    },

    // ELASTIC: {
    //     API: "https://<<HOST>>/elastic"
    // },
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `https://admin-hasta-la-vista.dev.rt.com/api/backend`,
        WEBSOCKET: `wss://admin-hasta-la-vista.dev.rt.com/ws`
        // ELASTIC_API: `https://admtest.vm.ru/elastic`,
    },

    UPLOAD: {
        "path": "https://admin-hasta-la-vista.dev.rt.com/api/backend/media"
    },
    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `https://auth.rt.com/`,
        // current project domain
        PROJECT_DOMAIN: `admin-hasta-la-vista.dev.rt.com`,
        // current project url path
        PROJECT_PATH: `http://admin-hasta-la-vista.dev.rt.com`,
        // path for oauth api
        API: `https://auth.rt.com/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://auth.rt.com/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
        AUTH_HEADER: 'X-Access-Token'
    },
    VIEWS: {
        documents: {
            "gallery": "default_document",
            "category": "category_view",
            "tag": "tag_view",
            "page": "page_view",
        },
        documents_add: {

            "category": "add_category",
            "tag": "add_tag",
            "page": "add_page",
        }
    },
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',
        },
        {
            'title': 'DOCUMENTS',
            'path': '/documents/gallery',
            'icon': 'container',
            'subroutes': [
                {
                    'path': '/documents/:type',
                    'component': 'default_document',
                },
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
            ],
        },
        {
            'title': 'PUSH',
            'path': '/push',
            'icon': 'notification',
            'component': 'push',
        },
        {
            'title': 'CATEGORIES',
            'path': '/documents/category',
            'icon': 'folder',
            'component': 'default_document',
        },

       
        {
            'title': 'MEDIA',
            'path': '/media',
            'icon': 'picture',
            'component': 'media',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },

            ]
        }
    ],
};

export default staging;
