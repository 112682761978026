import { combineReducers } from 'redux';
import DocumentsReducer from './documents/reducer';
import GroupsReducer from './groups/reducer';
import MediaReducer from './media/reducer';
import PermissionsReducer from './permissions/reducer';
import UserReducer from './user/reducer';
import WidgetReducer from './widgets/reducer';
import ProfilesReducer from './profiles/reducer';
import PushReducer from './push/reducer';

export default combineReducers({
    UserReducer,
    GroupsReducer,
    PermissionsReducer,
    DocumentsReducer,
    MediaReducer,
    WidgetReducer,
    ProfilesReducer,
    PushReducer,
});
