import React from 'react';
import {Layout} from 'antd';
import asyncComponent from './../core/components/async';
import Config from './../config';
import { AppConsumer } from './../context/app';


// const getUserPermissions = (p) => {
//     if (p && p.info) {
//         if (p.info.permissions && Array.isArray(p.info.permissions)) {
//             return p.info.permissions;
//         }
//     }
//     return false;
// }

const Menu = asyncComponent(() => {
    return import(`./../core/components/menu.js`);
});

const Header = asyncComponent(() => {
    return import(`./../core/components/header/index.js`);
});

const Router = asyncComponent(() => {
    return import('./../core/router/index.js');
});


export class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    render() {
        const menu = Config.MENU;
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Sider
                    collapsible
                    className={`sidebar`}
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    <div className={`logo`}/>
                    <Menu items={menu || []} />
                </Layout.Sider>
                <Layout>
                    <Layout.Header className={`app_header`} style={{paddingLeft: '0'}}>
                        <Header consumer={AppConsumer}/>
                    </Layout.Header>
                    <Router {...this.props} />
                </Layout>
            </Layout>
        );
    }
}

export default DefaultLayout;
